







































































import { Vue, Component, Prop } from "vue-property-decorator";
import Project from "@/entity/Project";
import ProjectBuyersService from "@/services/ProjectBuyersService";
import { BuyerStatResponse, BuyerStatStatus } from "@/entity/ProjectBuyer";
import Loader from "@/components/common/Loader.vue";
import PieChart from "@/components/charts/PieChart.vue";
import { sortBy } from "lodash";
import fileDownload from "js-file-download";
import moment from "moment";
import { DATE_FORMAT } from "@/constants";

@Component({
  components: {
    PieChart,
    Loader,
  },
})
export default class ProjectStatisticsView extends Vue {
  @Prop({ required: true }) project!: Project;

  isFetchingStatuses = false;
  statuses: BuyerStatStatus[] = [];
  isFetchingRejections = false;
  rejections: BuyerStatResponse[] = [];

  findings = "";
  nextSteps = "";
  isDownloading = false;

  async fetchStatusCount() {
    try {
      this.isFetchingStatuses = true;
      this.statuses = await ProjectBuyersService.getBuyerStatsStatus(
        this.project.id as number
      );
    } finally {
      this.isFetchingStatuses = false;
    }
  }

  async fetchBuyerResponsesStat() {
    try {
      this.isFetchingRejections = true;
      this.rejections = await ProjectBuyersService.getBuyerStatsResponse(
        this.project.id as number
      );
    } finally {
      this.isFetchingRejections = false;
    }
  }

  get translatedStatuses() {
    const translated = this.statuses.map((status) => ({
      ...status,
      eng: this.$t(status.status).toString(),
    }));
    return sortBy(translated, "eng");
  }

  get sortedRejections(): BuyerStatResponse[] {
    return sortBy(this.rejections, "dismissedStatus");
  }

  async exportStatisticsReport() {
    try {
      this.isDownloading = true;
      if (this.project.id) {
        const pdf = await ProjectBuyersService.downloadBuyerStatsReport(
          this.project.id,
          {
            findings: this.findings,
            nextSteps: this.nextSteps,
          }
        );
        const date = moment().format(DATE_FORMAT);
        fileDownload(
          pdf,
          `${this.project.projectContact.name}_buyers_statistics_report_${date}.pdf`
        );
      }
    } catch (error) {
      console.error(error);
      this.$snackbarError(this.$tc("apiErrors.unableToSave"));
    } finally {
      this.isDownloading = false;
    }
  }

  created() {
    this.fetchStatusCount();
    this.fetchBuyerResponsesStat();
  }
}
